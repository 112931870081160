import React from 'react';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import { 
  BasePage,
  pageTypes, 
  getRouteForPage } from './components/BasePage';
import './App.css';

function App() {
  const router = createBrowserRouter([
    {
      path: getRouteForPage(pageTypes.home),
      element: <BasePage pageType={pageTypes.home} />,
      errorElement: <ErrorPage />
    },
    {
      path: getRouteForPage(pageTypes.about),
      element: <BasePage pageType={pageTypes.about} />
    },
    {
      path: getRouteForPage(pageTypes.issues),
      element: <BasePage pageType={pageTypes.issues} />
    },
    {
      path: getRouteForPage(pageTypes.contact),
      element: <BasePage pageType={pageTypes.contact} />
    }
  ]);

  return (
    <>
      <React.StrictMode>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
      </React.StrictMode>
    </>
  );
}

export default App;
