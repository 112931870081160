import { ReactComponent as Logo } from '../images/KeithForAurora-v2-long-transparent.svg';

const Footer = () => {
    return <>
        <div className="footer-container">
            <div className="footer--content">
                <b>Paid for individually by Keith Larson</b><br />
                <a className="footer--link" href="mailto:keithforaurora@gmail.com">keithforaurora@gmail.com</a> | <a className="footer--link" href="tel:+16304920276">(630) 492-0276</a>
                <br /> My political committee for donations will be created soon and once that is set up, the hosting costs for this
                website will be covered by my committee.
            </div>
            <Logo className="footer--logo_img"
                //src={require('../images/KeithForAurora-logo-long-transparent.png')}
                alt="Keith Larson for Aurora Alderman At Large"
                aria-label="Logo and link to home page" />
        </div>
    </>;
};

export default Footer;