import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
    const error = useRouteError();

    return <>
        <h1>Error</h1>
        <p>
            <i>{error.statusText || error.message}</i>
        </p>
    </>;
};

export default ErrorPage;